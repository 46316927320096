@tailwind base;
@tailwind components;
@tailwind utilities;


.bg-c-1{
  background-color: rgb(11, 36, 71);
}

.bg-c-2{
  background-color: rgb(25, 55, 109);
}

.bg-c-3{
  background-color: rgb(87, 108, 188);
}

.bg-c-4{
  background-color: rgb(165, 215, 232);
}

.ho:hover{
  background-color: rgb(165, 215, 232);
}


.text-ho:hover{
  color: rgb(165, 215, 232);
}


.text-c-1{
  color: rgb(11, 36, 71);
}

.text-c-2{
  color: rgb(25, 55, 109);
}

.text-c-3{
  color: rgb(87, 108, 188);
}

.text-c-4{
  color: rgb(165, 215, 232);
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  line-height: 1.75;
  max-width: 120rem;
  margin-inline: auto;
}

.jumbotron-bg{
  background-image: url(./assets/images/happy-bearded-businessman-or-trader-in-formalwear-and-eyeglasses-e1667143257261.jpg);
}

.jumbotron-b{
  border-bottom-left-radius: 30rem;
  border-bottom-right-radius: 30rem;
}

.bg-why{
  background-image: url(./assets/images/business-crisis-management-768x511.jpg)
}

.bg-footer{
  background-image: url(./assets/images/foorter\ bg.png)
}

input:invalid[focused="true"] ~ small{
  display: block;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background-color: transparent; 
}

::-webkit-scrollbar-track {
  background-color: transparent; 
}

.text-xxs {
  font-size: xx-small;
}

.bg-luminary{
  background-image: url(./assets/images/Polygon\ Luminary.svg)
}
